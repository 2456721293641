<template>
  <dashboard>
    <div class="vleresoShoferin">
      <div class="container animate__animated animate__fadeInRight">
        <div class="row">
          <!-- Order informations -->
          <div class="col col-12">
            <div class="orderInfo">
              <div class="title dsc">
                <h1>Emri i biznesit:</h1>
                <p>Online Shop</p>
              </div>

              <div class="barCode dsc">
                <h1>Numri i porosisë:</h1>
                <p>#NSH134493250043</p>
              </div>

              <div class="driverName dsc">
                <h1>Emri i shoferit:</h1>
                <p>Besmir Kastrati</p>
              </div>
              <div class="phone dsc">
                <h1>Numri kontaktues i shoferit:</h1>
                <p>+383 49 884 114</p>
              </div>
            </div>
            <hr />
          </div>
          <!-- Order Informations End -->

          <!-- Rate -->

          <div class="col col-12" v-if="hasRated == true">
            <div class="hasRated">
              <h1>
                Ky shërbim është vlerësuar mëparë, nuk mundeni të vlerësoni
                përsëri!
              </h1>
            </div>
          </div>

          <div class="col col-12" v-if="hasRated == false">
            <div class="rateService" v-if="!isClicked == true">
              <div class="title">
                <h1>
                  <span data-feather="star"></span>
                  Si ishte shërbimi i postierit?
                </h1>
              </div>

              <div class="ddR">
                <div class="txt">
                  <h1>
                    Vlerësimi juaj na ndihmonë në përmirësimin e shërbimeve
                    tona!
                  </h1>
                </div>
                <div class="emojiDiv">
                  <img
                    v-for="(rating, index) in emojiRate"
                    :key="index.rating"
                    :src="rating"
                    alt=""
                    @click="rateEmoji(index + 1, rating)"
                    v-bind:class="{ active: isActive === index + 1 }"
                  />
                </div>
              </div>
            </div>

            <div class="afterClick" v-if="isClicked == true">
              <div class="divTxt">
                <h1>{{ afterClick }}</h1>
                <img :src="selectedEmoji" alt="" />
              </div>

              <div class="divWhy">
                <p>Cilat janë arsyet e vlerësimit?</p>
              </div>

              <div class="divReasons">
                <div
                  class="myReason"
                  @click="addReasonsFunction(value, index)"
                  v-for="(value, index) in reasons"
                  :key="index.value"
                >
                  <button
                    v-bind:class="{ activeReason: addReasons.includes(value) }"
                  >
                    <span class="pointIcon" v-if="!addReasons.includes(value)"
                      ><i class="far fa-hand-point-right"></i
                    ></span>
                    <span v-if="addReasons.includes(value)"
                      ><i class="fas fa-check"></i
                    ></span>
                    {{ value }}
                  </button>
                </div>

                <div class="myReason">
                  <button
                    v-bind:class="{ activeReason: triggerComment == true }"
                    @click="triggerComment = !triggerComment"
                  >
                    <span v-if="triggerComment != true"
                      ><i class="fas fa-plus"></i
                    ></span>
                    <span v-if="triggerComment == true"
                      ><i class="fas fa-check"></i>
                    </span>
                    Shtoni një koment për shërbimin
                  </button>
                </div>
              </div>

              <div class="addcomment" v-if="triggerComment == true">
                <div class="myReason">
                  <textarea
                    name="comment"
                    v-model="commentText"
                    @change="addComment()"
                    id="comment"
                    cols="20"
                    rows="5"
                    placeholder="Tregoni arsyen tuaj këtu!"
                  ></textarea>
                </div>
              </div>

              <hr />

              <div class="divBtn">
                <button @click="returnRating()">
                  <i class="fas fa-star"></i> Rivlerëso
                </button>

                <button v-if="addReasons.length >= 2">
                  <i class="fas fa-paper-plane"></i> Vlerëso
                </button>
              </div>
            </div>
          </div>

          <!-- Rate End -->
        </div>
      </div>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from "../DashHome/dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      hasRated: false,
      isActive: null,
      isClicked: false,
      triggerComment: false,
      commentText: "",
      afterClick: "",
      selectedEmoji: null,
      reasons: [],
      addReasons: [],
      emojiRate: [
        require("../../assets/icon/angry-face-emoji.png"),
        require("../../assets/icon/sad-face-emoji.png"),
        require("../../assets/icon/meh-face-emoji.png"),
        require("../../assets/icon/smiling-face-emoji.png"),
        require("../../assets/icon/satisfied-face-emoji.png"),
      ],
    };
  },
  methods: {
    // Add reasons when clicking
    addReasonsFunction: function(value, index) {
      this.isReason = true;
      const indeksi = this.addReasons.indexOf(value);
      if (this.addReasons.includes(value)) {
        this.addReasons.splice(indeksi, 1);
      } else {
        this.addReasons.splice(index, 0, value);
      }
    },
    // End

    // Add a comment
    addComment: function() {
      clearTimeout(timeOut);
      var timeOut = setTimeout(() => {
        this.addReasons.push(this.commentText);
        this.triggerComment = false;
      }, 300);
    },
    // End

    // When clicked emoji hide and show
    returnRating: function() {
      this.isClicked = false;
      this.isReason = false;
      this.triggerComment = false;
      this.addReasons = [];
    },
    rateEmoji: function(index, rating) {
      this.isActive = index;
      this.isClicked = true;
      this.selectedEmoji = rating;
      this.reasons = [];
      this.addReasons = [];
      this.addReasons.push({ ratingValue: index });
      if (index == 1) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Na vjen shumë keq për eksperiencën tuaj...";
        this.reasons.push(
          "Sjellja",
          "Shërbimi",
          "Nuk përgjigjej",
          "Vonesa",
          "Kam paguar më shumë se që duhet"
        );
      } else if (index == 2) {
        this.afterClick =
          "( Nota: " + index + " ) " + "Cilat ishin pakënaqësit e tua?";
        this.reasons.push(
          "Sjella!",
          "Nuk përgjigjej!",
          "Produkti!",
          "Kam paguar më shumë se që duhet!"
        );
      } else if (index == 3) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Faleminderit për vlerësimin tuaj! Do të përmirësohemi edhe më shumë";
        this.reasons.push(
          "U vonua pak!",
          "Sjellje jo e edhe e mirë!",
          "Nuk e dinte adresën mirë!",
          "Shpresoja të ishte shërbim më i mirë!"
        );
      } else if (index == 4) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Na vjenë mirë për vlerësimin tuaj! Si mundemi të përmirësohemi ende më shumë?";
        this.reasons.push(
          "I saktë në adresë!",
          "I sjellshëm!",
          "Shërbim i shpejtë!"
        );
      } else if (index == 5) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Faleminderit! Shpresojmë të punojmë përsëri me ju.";
        this.reasons.push(
          "I sjellshëm!",
          "Ishte i shpejtë!",
          "Komunikim i mirë!",
          "Shërbim fantastik!"
        );
      }
    },
    // End
  },
};
</script>
